import * as React from "react"
import { navigate } from 'gatsby-link';
import styled from '@emotion/styled'
import Layout from '../components/Layout/Layout';
import { 
  PageTitleHeader, 
  Breakpoints, 
  blissfulColour,
  FormInputBlock,
  InputFieldset,
  FormInputText,
  FormSelect,
  FormTextAreaBlock,
  FormInputRadio,
  FormTextArea,
  FormButton,
  RequiredAsterisk
 } from '../globalStyles';

import FlavourList from "../content/flavours.json";
import RentalList from "../content/rentals.json";

const Container = styled.div`
  margin: 3rem auto;
  max-width: 960px;
  padding: 0 20px;
`

const PickupFee = styled.p`
  font-style: italic;
  color: gray;
  margin-top: 1px;
  margin-bottom: 1px;
`

function encode(data) {
  return Object.keys(data).map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');
}

function convertDateFormat(date) {
  const newDate = new Date(date.toString().replace(/-/g, '/'));

  const formattedDate = newDate.toLocaleDateString('en-us', {
    timeZone: 'America/Toronto',
    weekday: 'long',
    year: 'numeric', 
    month: 'long', 
    day: 'numeric'
  });

  return formattedDate;
}

function convertTimeFormat(time) {
  // time is optional so break early if value doesn't exist 
  if (!time) return;

  time = time.split(':'); // convert to array
 
  // fetch
  var hours = Number(time[0]);
  var minutes = Number(time[1]);

  let timeValue;

  if (hours > 0 && hours <= 12) {
    timeValue= "" + hours;
  } else if (hours > 12) {
    timeValue= "" + (hours - 12);
  } else if (hours == 0) {
    timeValue= "12";
  }

  timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;  // get minutes
  timeValue += (hours >= 12) ? " P.M." : " A.M.";  // get AM/PM

  return timeValue;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function convertStateToSingle(stateObject) {
  return Object.keys(stateObject).map((key) => {
    const newKey = capitalizeFirstLetter(key).split('_').join(' ');

    // don't add the key and value if value doesn't exist
    if (!stateObject[key]) return;

    return encodeURIComponent(newKey) + ': ' + encodeURIComponent(stateObject[key]);
  }).join('%0A');
 }

const ClientForm = () => {
  let pathname = typeof window !== "undefined" ? window.location.pathname : ""

  const updateSubject = (e) => {
    document.getElementById("email-subject").setAttribute('value', "Client information form for " + e.target.value);
  }

  const [ state, setState ] = React.useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // convert Dates to more readable format
    Object.assign(state, { date_order_placed: `${convertDateFormat(state['date_order_placed'])} (${state['date_order_placed']})` });
    Object.assign(state, { date_order_due: `${convertDateFormat(state['date_order_due'])} (${state['date_order_due']})` });

    // convert Times to more readable format
    Object.assign(state, { time_of_event: state['time_of_event'] ? `${convertTimeFormat(state['time_of_event'])} (${state['time_of_event']})` : undefined });
    Object.assign(state, { time_venue_available: state['time_venue_available'] ? `${convertTimeFormat(state['time_venue_available'])} (${state['time_venue_available']})` : undefined });

    const form = e.target;

    let stringBody = encode({
      'form-name': form.getAttribute('name'),
      'subject': 'Client information form for ' + state['name_of_client']
    });

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: stringBody + '&message=' + convertStateToSingle(state)
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  function regularField() {
    return !(state['cake_type'] && state['delivery_or_pickup'])
  }

  function weddingField() {
    return !(state['cake_type'] == 'Wedding Cake' && state['delivery_or_pickup'])
  }

  function deliveryField() {
    return !(state['cake_type'] && state['delivery_or_pickup'] == 'Delivery')
  }

  return(
  <Layout
    title="Client Information Form"
    description="In order to proceed with your order, please complete the form."
    noIndex={true}
    pathname={pathname}
  >
    <Container>
      <PageTitleHeader> Client Information Form </PageTitleHeader>

      <p>
        In order to proceed with your order, please complete the form below. 
      </p>

      <form name="client information" encType="multipart/form-data" action="/client-information-form/thanks" method="POST" data-netlify="true" onSubmit={handleSubmit}>
      
        <input type="hidden" name="form-name" value="Client Information" />
        <input type="hidden" name="subject" value="Client Information Form" id="email-subject"/>

        <FormInputBlock>
          <InputFieldset>
            <legend>Type of cake<RequiredAsterisk>*</RequiredAsterisk></legend>
              <label>
                <FormInputRadio type="radio" name="cake_type" id="cake_type" value="Wedding Cake" required onChange={handleChange}/> Wedding Cake
              </label>
              <label>
                <FormInputRadio type="radio" name="cake_type" id="cake_type" value="Other Cake" required onChange={handleChange}/> Other
              </label>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset>
            <legend>Do you require delivery or pickup?<RequiredAsterisk>*</RequiredAsterisk></legend>
              <label>
                <FormInputRadio type="radio" name="delivery_or_pickup" id="delivery_or_pickup" value="Delivery" required onChange={handleChange}/> Delivery
              </label>
              <label>
                <FormInputRadio type="radio" name="delivery_or_pickup" id="delivery_or_pickup" value="Pickup" required onChange={handleChange}/> Pickup
              </label>
              { state['delivery_or_pickup'] == 'Delivery' ?
              <div>
                  <p>Delivery and setup prices usually vary from $50-100</p>
                  <p>Cake deliveries will be made half an hour-1.5 hours before your event begins unless stated otherwise. 
                    Please ensure with your decorating team to ensure your cake table is ready for delivery as often we have multiple deliveries scheduled back to back and cannot wait for them to setup once we’re already there. 
                    If the cake table is not ready, the cake will be placed safely at the venue. You will have to arrange for someone to place it on the cake table once the cake table is ready. </p>
                  <p>All weekday deliveries between 3-7pm may be subject to an additional charge of 15% due to heavy traffic at those times. </p>
                </div>
                : 
                ""
              }
              { state['delivery_or_pickup'] == 'Pickup' ?
              <div>
                <br></br>
                  <legend>What size box do you need?<RequiredAsterisk>*</RequiredAsterisk></legend>
                  <ul>
                    <li>Tall box, $10 additional charge. (Recommended for tiered cakes) </li>
                    <li>Standard box, no additional charge</li>
                  </ul>
                  <label>
                    <FormInputRadio type="radio" name="pickup_box_size" id="pickup_box_size_tall" value="Tall Box" required onChange={handleChange}/> Tall Box
                  </label>
                  <label>
                    <FormInputRadio type="radio" name="pickup_box_size" id="pickup_box_size_standard" value="Standard Box" required onChange={handleChange}/> Standard Box
                  </label>
                </div>
                : 
                ""
              }
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset disabled={regularField()}>
            <legend> Full Name of Client{!regularField() && <RequiredAsterisk>*</RequiredAsterisk>} </legend>
            <FormInputText type="text" id="name_of_client" required onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset disabled={weddingField()}>
            <legend> Names of Couple{!weddingField() && <RequiredAsterisk>*</RequiredAsterisk>} </legend>
            <FormInputText type="text" id="name_of_couple" required onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset disabled={regularField()}>
            <legend> Phone Number{!regularField() && <RequiredAsterisk>*</RequiredAsterisk>} </legend>
            <FormInputText type="phone" id="phone" required onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset disabled={regularField()}>
            <legend> Email{!regularField() && <RequiredAsterisk>*</RequiredAsterisk>} </legend>
            <FormInputText type="email" id="email" required onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
         <InputFieldset disabled={regularField()}>
         <legend> Emergency Contact{!regularField() && <RequiredAsterisk>*</RequiredAsterisk>} </legend>
          <FormInputText type="text" id="emergency_contact" required onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset disabled={regularField()}>
          <legend> Emergency Contact Number{!regularField() && <RequiredAsterisk>*</RequiredAsterisk>} </legend>
          <FormInputText type="phone" id="emergency_contact_number" required onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset disabled={weddingField()}>
          <legend> Wedding Coordinator Name </legend>
          <FormInputText type="text" id="wedding_coordinator_name" onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset disabled={weddingField()}>
          <legend> Wedding Coordinator Number </legend>
          <FormInputText type="phone" id="wedding_coordinator_number" onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset disabled={weddingField()}>
            <legend>Wedding Type{!weddingField() && <RequiredAsterisk>*</RequiredAsterisk>}</legend>
              <label>
                <FormInputRadio type="radio" name="wedding_type" id="wedding_type" value="Indoor" required onChange={handleChange}/> Indoor
              </label>
              <label>
                <FormInputRadio type="radio" name="wedding_type" id="wedding_type" value="Outdoor" required onChange={handleChange}/> Outdoor
              </label>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset disabled={deliveryField()}>
            <legend> Location of Venue (if delivery is required, please include unit, room number or special instructions){!deliveryField() && <RequiredAsterisk>*</RequiredAsterisk>}</legend>
            <FormInputText type="text" id="location_of_venue" onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset disabled={regularField()}>
            <legend>Date Order Placed{!regularField() && <RequiredAsterisk>*</RequiredAsterisk>}</legend>
            <FormInputText type="date" id="date_order_placed" placeholder="Date Order Placed*" required onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset disabled={regularField()}>
            <legend>Date Order Due{!regularField() && <RequiredAsterisk>*</RequiredAsterisk>}</legend>
            <FormInputText type="date" id="date_order_due" placeholder="Date Order Due*" required onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset disabled={regularField()}>
            <legend>Time of Event{!regularField() && <RequiredAsterisk>*</RequiredAsterisk>}</legend>
            <FormInputText type="time" id="time_of_event" placeholder="Time of Event*" onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset disabled={deliveryField()}>
            <legend>Time the Venue is Available for Decor (This helps us know the time the venue is open for delivery){!deliveryField() && <RequiredAsterisk>*</RequiredAsterisk>}</legend>
            <FormInputText type="time" id="time_venue_available" placeholder="Time the Venue is Available for Decor (This helps us know the time the venue is open for delivery)*" onChange={handleChange}/>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset disabled={regularField()}>
            <legend>Would you like a cake stand rental? (view stands and details <a href="/rentals" target="_blank">here</a>){!regularField() && <RequiredAsterisk>*</RequiredAsterisk>}</legend>
            <label>
              <FormInputRadio type="radio" name="cake_stand_rental" id="cake_stand_rental" value="Yes" required onChange={handleChange}/> Yes
            </label>
            <label>
              <FormInputRadio type="radio" name="cake_stand_rental" id="cake_stand_rental" value="No" required onChange={handleChange}/> No
            </label>

            <br></br> <br></br>

            <FormSelect id="cake_stand" onChange={handleChange} required disabled={!(state['cake_stand_rental']=="Yes")}>
              <option hidden selected>Select a cake stand...</option>
              { RentalList.map((rental) => {
               return <option key={rental.name} value={rental.name}> { `${rental.name} - $${rental.price}` } </option>
                })
              }
            </FormSelect>
          </InputFieldset>
        </FormInputBlock>

        <FormInputBlock>
          <InputFieldset disabled={regularField()}>
            <legend>Flavour of Cake (Please click <a href="/flavours" target="_blank">here</a> to view flavours and note that prices of flavours vary){!regularField() && <RequiredAsterisk>*</RequiredAsterisk>}</legend>
            <FormSelect id="flavour_of_cake" onChange={handleChange} required>
              <option hidden selected>Select a flavour...</option>
              { FlavourList.flavour_categories.map((category) => {
                return <>
                  { category.flavours.map((flavour) => {
                    return <option key={flavour.flavour} value={flavour.flavour}> { `${category.title} - ${flavour.flavour}` } </option>
                  }) }
                </>
                })
              }
            </FormSelect>

            <br></br> <br></br>

            <FormTextAreaBlock>
              <FormTextArea name="message" id="message" rows="4" cols="50" placeholder="Notes" onChange={handleChange}/>
            </FormTextAreaBlock>
          </InputFieldset>
        </FormInputBlock>

        <FormButton type="submit"> Submit </FormButton>

      </form>

    </Container>
  
  </Layout>
  )
}

export default ClientForm

